* {
  font-family: "Inter", sans-serif !important;
  font-size: 20px;
  font-weight: bolder;
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

*:focus,
*:active {
  outline: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
  border-left: 1px solid #1f1f1f;
  border-right: 1px solid #1f1f1f;

  @media only screen and (max-width: 600px) {
    height: 0px;
    width: 0px;
  }
}

::-webkit-scrollbar-track {
  background-color: #282828;
  border-left: 1px solid #1f1f1f;
  border-right: 1px solid #1f1f1f;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffffff33;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  background-clip: content-box;
}

html {
  overflow: unset !important;
}

body {
  margin: 0px !important;
  overflow: unset !important;
  background-color: #000000;
  cursor: default;
}

.max-width-100 {
  @media only screen and (min-width: 1400px) {
    max-width: calc(100vw - 150px) !important;
  }
}

.swiper {
  overflow: unset;
}

.swiper-3d {
  perspective: 1500px;
}

.game-loading {
  width: 100px;
  margin: auto;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgba(255, 255, 255, 0.5) 30%, #ffffff00 0 70%, rgb(255, 255, 255) 0) 50%/8% 100%,
    linear-gradient(90deg, rgba(255, 255, 255, 0.25) 30%, #ffffff00 0 70%, rgba(255, 255, 255, 0.75) 0) 50%/100% 8%;
  // linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
  // linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);
}

.game-loading::before,
.game-loading::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.game-loading::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

.vegastar-logo {
  a {
    position: relative;
    display: block;
    text-align: center;
    line-height: 0;
    -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .5));
    background-position: -100% 0;
    animation: maskAnimation 5s linear infinite;

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, transparent, white, transparent) no-repeat;
      background-position: inherit;
      background-size: 10% 100%;
      mask: url('#mask-firefox');
      -webkit-mask: url('../images/logo.png') center;
      -webkit-mask-size: contain;
    }
  }
}

@keyframes maskAnimation {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.main-menu-button {
  position: relative;
  display: block;
  text-align: center;
  -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .5));
  background-position: -500% 0;
  animation: menuMaskAnimation 2s linear infinite;

  &.menu1 {
    &::after {
      width: 97%;
      mask: url('#mask-firefox1');
      -webkit-mask: url('../images/buttons/TOP GAMES.png') center;
    }
  }

  &.menu2 {
    &::after {
      mask: url('#mask-firefox2');
      -webkit-mask: url('../images/buttons/PLAYSON.png') center;
    }
  }

  &.menu3 {
    &::after {
      mask: url('#mask-firefox3');
      -webkit-mask: url('../images/buttons/BOONGO.png') center;
    }
  }

  &.menu4 {
    &::after {
      mask: url('#mask-firefox4');
      -webkit-mask: url('../images/buttons/PRAGMATIC.png') center;
    }
  }

  &.menu5 {
    &::after {
      mask: url('#mask-firefox5');
      -webkit-mask: url('../images/buttons/FAVORITE.png') center;
    }
  }

  &.menu6 {
    &::after {
      mask: url('#mask-firefox6');
      -webkit-mask: url('../images/buttons/AMATIC.png') center;
    }
  }

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    height: 94%;
    top: 3%;
    left: 0;
    right: 0;
    bottom: 0;
    // transform: skewX(-25deg);
    background: linear-gradient(to right, fade_out(#fff, 1) 0%, fade_out(#fff, 0.7) 100%) no-repeat;
    background-position: inherit;
    background-size: 50% 100%;
  }
}

@keyframes menuMaskAnimation {
  0% {
    background-position: -500% 0;
  }

  100% {
    background-position: 400% 0;
  }
}